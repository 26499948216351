<template>
  <div class="sidebar-logo-container" :class="{ 'collapse': collapse }"
    :style="{ backgroundColor: sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground }">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <img style="width: 22px;height: 22px;margin-right: 10px;" v-if="logo" :src="logo" class="sidebar-logo" />
        <h1 v-else class="sidebar-title"
          :style="{ color: sideTheme === 'theme-dark' ? variables.logoTitleColor : variables.logoLightTitleColor }">{{
              title
          }} </h1>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img style="width: 22px;height: 22px;margin-right: 10px;" v-if="logo" :src="logo" class="sidebar-logo" />
        <h1 class="sidebar-title"
          :style="{ color: sideTheme === 'theme-dark' ? variables.logoTitleColor : variables.logoLightTitleColor }">{{
              title
          }} </h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
import logoImg from '@/assets/logo/logo.png'
import variables from '@/assets/styles/variables.scss'
import { mapState } from 'vuex'
import store from '@/store'

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(['permission']),
    variables() {
      return variables;
    },
    sideTheme() {
      return this.$store.state.settings.sideTheme
    }
  },
  data() {
    return {
      title: '',
      logo: logoImg
    }
  },
  mounted() {
    if (!localStorage.getItem('personType')) {
      let href = window.location.href
      console.log(window.location.href, 'href');
      if (href.indexOf('school') == -1) {
        store.commit('setPersonType', 1)
      } else {
        store.commit('setPersonType', 3)
      }
      store.commit('setRole', 2)
      console.log(store, 'personType');
    }
    // console.log(this.permission.personType,789);
    if (localStorage.getItem('personType') == 1) {
      this.title = '数智化管理平台运营端'
      return
    }
    if (localStorage.getItem('personType') == 3) {
      this.title = '数智化管理学校端'
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #2b2f3a;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 60px;
      height: 32px;
      vertical-align: middle;
      margin-right: -16px;

    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
