<template>
    <div class="box">
        <div >
            <div class="content-index">
                <!-- <dv-loading v-if="isLoading" class="loading-box">加载中...</dv-loading> -->
                <!-- 自适应容器 -->
                <ScaleScreen :width="1920" :height="1080" class="scale-wrap" :selfAdaption="selfAdaption">
                    <div class="top">
                        <div class="top-l"> {{ date.year }}-{{ date.month }}-{{ date.date }} {{ date.hours }}:{{
                            date.minutes }}</div>
                        <!-- @click="click" -->
                        <div class="week">{{ weeks }}</div>
                        <div class="top-cot">新域伟科技无感考勤系统</div>
                        <div class="top-r" >
<!--                           <div v-if="ipConfig.hostIp">本机IP:{{ipConfig.hostIp}}<s @click="openIpConfigDialog()" style="margin-left: 15px;color: #00D43C">修改</s></div>-->
<!--                           <div v-else @click="openIpConfigDialog()">配置本机IP</div>-->
                        </div>
                    </div>
                    <div class="content">
                       <div class="leftChoose" @click="openOrClose">
                          <img  style="width: 35px;height: 40px" :src="imgChange">
                       </div>
                        <div v-if="leftOpen" class="leftOpen">
                           <div v-for="(item,index) in hostList" class="theHost">
                              <div style="margin-bottom: 10px" class="chooseHost" @click="changeHost(item)">主机{{index+1}}：{{item.hostName}}</div>
<!--                              <div v-for="(s,i) in item.cameraList" class="chooseCamera" @click="changeCamera(s)">摄像头：{{s.cameraName}}</div>-->
                           </div>
                        </div>
                       <div style="width: 98%;height: 100%;display: flex;flex-wrap: wrap">
                          <div v-for="(item,index) in peopleList"  class="people">
                              <img v-if="item.image" :src="item.image" style="width: 65%;height: 164px;margin-top: 10px">
                              <div v-if="item.name" style="color: #FFFFFF;font-size: 21px;margin-top: 7px">姓名：{{item.name}}</div>
                              <div v-if="item.time" style="color: #FFFFFF;font-size: 16px;margin-top: 7px">时间：{{item.time}}</div>
                          </div>
                       </div>
<!--                       <div class="video">-->
<!--                         <video  id="video"  autoplay muted width="100%" height="100%"style="object-fit: fill"></video>-->
<!--                       </div>-->
<!--                      <div class="video">-->
<!--                        <video  ref="videoElement"  autoplay muted   width="100%" height="100%"style="object-fit: fill"></video>-->
<!--                      </div>-->
<!--                      <div class="rightShow" >-->
<!--                        <div v-for="(item,index) in peopleList" style="text-align: center">-->
<!--                          <img :src="item.image" style="width: 100%;height: 164px;margin-top: 30px">-->
<!--                          <div style="color: #FFFFFF;font-size: 21px;margin-top: 10px">姓名：{{item.name}}</div>-->
<!--                          <div style="color: #FFFFFF;font-size: 16px;margin-top: 10px">时间：{{item.time}}</div>-->
<!--                        </div>-->
<!--                      </div>-->
                    </div>
                </ScaleScreen>
            </div>
<!--          <el-dialog title="配置本机IP" :visible.sync="open" width="680px" append-to-body @closed="closeDialog">-->
<!--            <el-form ref="ipConfig" :model="ipConfig" :rules="ipConfigRule" label-width="180px" @submit.native.prevent>-->
<!--              <el-row style="position: relative;">-->
<!--                <el-col>-->
<!--                  <el-form-item label="本机IP:" prop="hostIp">-->
<!--                    <el-input placeholder="请输入"  v-model="ipConfig.hostIp"-->
<!--                              style="width: 200px">-->
<!--                    </el-input>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
<!--              </el-row>-->
<!--            </el-form>-->
<!--            <div slot="footer" class="dialog-footer">-->
<!--              <el-button type="primary"  @click="submitIpConfig">确 定</el-button>-->
<!--              <el-button @click="ConfigCancel">取 消</el-button>-->
<!--            </div>-->
<!--          </el-dialog>-->
        </div>
    </div>
</template>
<script>
import DataSetSchool from '../../DataSetSchool.vue';
import ScaleScreen from "@/components/scale-screen/scale-screen.vue";
import { getHostCameraList,getHostIpBySchool,saveHostIpBySchool,edgeHostInfo} from "@/api/system/DataSet"
import mqttHandle from '@/utils/mqttHandle'
export default {
    components: { DataSetSchool, ScaleScreen },
    data() {

        return {
          mqttUser: 'admin',
          mqttPwd: 'HMi57ayls6',
          url: 'tcp://39.98.176.146:1883',
          webRtcServer: null,
          camera_ip: '192.168.0.120:8000',
          flvUrl:'',
            date: {
                year: null,
                month: null,
                date: null,
                day: null,
                hours: null,
                minutes: null,
                seconds: null
            },
            leftOpen:false,
            selfAdaption: true,
            hostCameraList:[],
            hostList:[],
            schoolId:this.$store.state.user.schoolId,
            hostSn:'',
            cameraRtsp:'',
            cameraId:'',
            open:false,
            peopleList:[{image:require('../../../assets/DataSet/peopleNull.png')},
              {image:require('../../../assets/DataSet/peopleNull.png')},
              {image:require('../../../assets/DataSet/peopleNull.png')},
              {image:require('../../../assets/DataSet/peopleNull.png')},
              {image:require('../../../assets/DataSet/peopleNull.png')},
              {image:require('../../../assets/DataSet/peopleNull.png')},
              {image:require('../../../assets/DataSet/peopleNull.png')},
              {image:require('../../../assets/DataSet/peopleNull.png')},
              {image:require('../../../assets/DataSet/peopleNull.png')},
              {image:require('../../../assets/DataSet/peopleNull.png')},
              {image:require('../../../assets/DataSet/peopleNull.png')},
              {image:require('../../../assets/DataSet/peopleNull.png')},
              {image:require('../../../assets/DataSet/peopleNull.png')},
              {image:require('../../../assets/DataSet/peopleNull.png')},
              {image:require('../../../assets/DataSet/peopleNull.png')}],
            ipConfig:{hostIp:''},
            ipConfigRule:{
               hostIp:{ required: true, message: "请输入本机Ip", trigger: "blur" }
            },
        }
    },
  mounted() {
    //监听cameraRtsp变化，生成流
    // this.webRtcServer = new WebRtcStreamer('video',  this.ipConfig.hostIp)
    // this.webRtcServer.connect(this.cameraRtsp)
    // if (flvjs.isSupported()) {
    //   const videoElement = this.$refs.videoElement;
    //   const flvPlayer = flvjs.createPlayer({
    //     type: 'flv',
    //     url: this.flvUrl // 替换为你的视频流地址
    //   });
    //   flvPlayer.attachMediaElement(videoElement);
    //   flvPlayer.load();
    //   flvPlayer.play();
    // }
  },
  computed:{
      //左侧箭头的变化
    imgChange(){
      return  this.leftOpen?require('../../../assets/images/cameraScreemLeft1.png'):require('../../../assets/images/cameraScreemLeft.png')
    },
  },
  //销毁视频流
  beforeDestroy() {
    // this.webRtcServer.disconnect()
    // this.webRtcServer = null
    // if (this.flvPlayer) {
    //   this.flvPlayer.destroy();
    // }
  },
    created() {
        this.initHandle()
        this.getEdgeHostInfo()
        // this.getHostIpBySchool()
      console.log("缓存的人员数据",localStorage.getItem("userList"))
        //首次打开页面，加载查询到的第一个摄像头的画面
        setTimeout(()=>{
          // if (this.hostCameraList.length>0){
          //   this.cameraRtsp = this.hostCameraList[0].cameraList[0].cameraRtsp
          //   this.cameraId = this.hostCameraList[0].cameraList[0].cameraId
            // this.flvUrl='http://192.168.0.118/live?app=live&stream='+this.cameraId+'_stream0'
            // console.log("链接在此",this.flvUrl)
            //
            //   const videoElement = this.$refs.videoElement;
            //   const flvPlayer = flvjs.createPlayer({
            //     type: 'flv',
            //     url: this.flvUrl // 替换为你的视频流地址
            //   });
            //   flvPlayer.attachMediaElement(videoElement);
            //   flvPlayer.load();
            //   flvPlayer.play();

            // this.webRtcServer = new WebRtcStreamer('video',  this.ipConfig.hostIp)
            // this.webRtcServer.connect(this.cameraRtsp)
            // this.connectMqtt()
          // }
          console.log("缓存的人员数据",localStorage.getItem("userList"))
          if (localStorage.getItem("userList")){
            this.peopleList = JSON.parse(localStorage.getItem("userList"))
          }
          if (this.hostList.length>0){
            this.hostSn = this.hostList[0].sn
            this.connectMqtt()
          }
        },500)
    },

    methods: {
      // mqtt链接
      connectMqtt() {
        var mqtt;
        // var client;
        //创建链接，接收数据
        mqtt = new mqttHandle();
        this.client = mqtt.createConnect();
        this.client.subscribe(`/s/marmot-record/v1`, { qos: 1 }, err => {
          if (!err) {
            console.log('订阅成功',this.cameraId);
          } else {
            console.log('订阅失败');
          }
        })
        //信息监听事件
        this.client.on('message', (topic, message) => {
          this.isMsg = true
          console.log('收到message' , message.toString());
          console.log('收到topic' + topic);
          // 拉取图片
          let msg = JSON.parse(message)
          if (msg.body.type=='20011'){
            if (msg.body.deviceSn = this.hostSn && msg.body.data.data.recognition.matchDegree-0>75){
              //转换时间格式
              let time = ''
              if(msg.body.data.timestamp){
                const date = new Date(msg.body.data.timestamp)
                const year = date.getFullYear(); // 年
                const month = date.getMonth() + 1; // 月，注意getMonth()返回的月份是从0开始的
                const day = date.getDate(); // 日
                const hours = date.getHours(); // 小时
                const minutes = date.getMinutes(); // 分钟
                const seconds = date.getSeconds(); // 秒
                time = year + '-' +
                  ('0' + month).slice(-2) + '-' +
                  ('0' + day).slice(-2) + ' ' +
                  ('0' + hours).slice(-2) + ':' +
                  ('0' + minutes).slice(-2) + ':' +
                  ('0' + seconds).slice(-2);

              }


              let data = {
                image:msg.body.data.data.face.image,
                name:msg.body.data.data.recognition.matchName,
                id:msg.body.data.data.recognition.personId,
                time:time
              }
              if (this.peopleList.length>0){
                for (let i=0;i<this.peopleList.length;i++){
                  if (this.peopleList[i]!=''&&this.peopleList[i].id==data.id){
                    this.peopleList.splice(i,1)
                  }

                }
              }
              this.peopleList.unshift(data)
              if (this.peopleList.length>15){
                this.peopleList = this.peopleList.slice(0,15)
              }
              localStorage.setItem('userList', JSON.stringify(this.peopleList))
              console.log("人员列表",this.peopleList)
            }
          }
          this.loading = false;
        })
        //重连
        this.client.on('reconnect', (err) => {
          console.log('正在进行重连', err);
        })
        //失败
        this.client.on('error', (err) => {
          console.log('连接失败', err);
        })

      },

      //左上角时间展示
      initHandle() {
        this.timer = setInterval(() => {
          let now = new Date();
          this.date.year = now.getFullYear(); //  年份
          this.date.month = (now.getMonth() + 1) < 10 ? "0" + (now.getMonth() + 1) : (now.getMonth() + 1); //  月份，注意月份范围是0~11，5表示六月
          this.date.date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //  表示24号
          this.date.hours = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); // 19, 24小时制
          this.date.minutes = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); // 49, 分钟
          this.date.seconds = now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds(); // 22, 秒
        }, 1000)
        let week = new Date().getDay();
        if (week == 0) {
          this.weeks = "星期日";
        } else if (week == 1) {
          this.weeks = "星期一";
        } else if (week == 2) {
          this.weeks = "星期二";
        } else if (week == 3) {
          this.weeks = "星期三";
        } else if (week == 4) {
          this.weeks = "星期四";
        } else if (week == 5) {
          this.weeks = "星期五";
        } else if (week == 6) {
          this.weeks = "星期六";
        }
      },
      //打开左侧摄像头列表
      openOrClose(){
        this.leftOpen=(!this.leftOpen)
        if (this.leftOpen){
          this.getEdgeHostInfo()
        }
      },

      //获取主机列表
      getEdgeHostInfo(){
        edgeHostInfo({schoolId:this.schoolId}).then(s=>{
          this.hostList=s.data.rows
        })
      },

      //切换主机
      changeHost(row){
        this.hostSn = row.sn
      },

      //获取摄像头列表
      // getHostCameraList(){
      //   getHostCameraList({}).then(s=>{
      //     this.hostCameraList=s.data.rows
      //   })
      // },

      //切换摄像头
      // changeCamera(row){
      //   this.cameraRtsp=row.cameraRtsp
      //   this.cameraId=row.cameraId
        // this.flvUrl='http://192.168.0.118/live?app=live&stream='+this.cameraId+'_stream0'
        // console.log("链接",this.ipConfig.hostIp)
        // const videoElement = this.$refs.videoElement;
        // const flvPlayer = flvjs.createPlayer({
        //   type: 'flv',
        //   url: this.flvUrl // 替换为你的视频流地址
        // });
        // flvPlayer.attachMediaElement(videoElement);
        // flvPlayer.load();
        // flvPlayer.play();
        // this.webRtcServer = new WebRtcStreamer('video', this.ipConfig.hostIp)
        // this.webRtcServer.connect(this.cameraRtsp)
      // },

      //打开配置本机Ip弹框
      // openIpConfigDialog(){
      //   this.getHostIpBySchool()
      //   this.open=true
      // },

      //获取学校配置的本机IP
      // getHostIpBySchool(){
      //   getHostIpBySchool({repectNotCheckFlag:'1'}).then(s=>{
      //     if (s.code==200){
      //       this.ipConfig.hostIp = s.data.hostIp
      //       if (!s.data.hostIp || !s.data.hostIp=='undefind'){
      //         this.$modal.msgError("请配置本机IP后查看录像")
      //       }
      //     }
      //   })
      // },

      //保存本机Ip
      // submitIpConfig(){
      //   this.$refs["ipConfig"].validate(valid => {
      //     if (valid) {
      //       let data = {
      //         hostIp:this.ipConfig.hostIp
      //       }
      //       saveHostIpBySchool(data).then(s=>{
      //         if (s.code==200){
      //           this.$modal.msgSuccess('操作成功');
      //         }else {
      //           this.$modal.msgError(s.msg)
      //         }
      //         this.ConfigCancel()
      //
      //       })
      //     }
      //   })
      // },

      //关闭IP配置弹框
      // closeDialog(){
      //   this.ipConfig.hostIp=''
      //   this.$refs.ipConfig.clearValidate()
      //   setTimeout(()=>{
      //     this.getHostIpBySchool()
      //   },500)
      // },

      //取消IP配置弹框
      // ConfigCancel(){
      //   this.open=false
      //   this.ipConfig.hostIp=''
      //   this.$refs.ipConfig.clearValidate()
      //   setTimeout(()=>{
      //     this.getHostIpBySchool()
      //   },500)
      // }
    }
}
</script>
<style lang="scss" scoped>
.content-index {
    height: 100vh;
    background-color: #000511;

    .loading-box {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999999;
        background-color: rgba(0, 0, 0, 0.4);
        color: rgb(59, 230, 203);
    }

    .scale-wrap {
        background-image: url('../../../assets/DataSet/banner.png');
        background-size: cover;
    }

    .top {
        height: 135px;
        background: url(../../../assets/DataSet/top.png) no-repeat;
        background-size: 100% 100%;
        background-position: center;
        display: flex;
        justify-content: space-between;
        color: white;
        align-items: center;
        position: relative;

        .week {
            position: absolute;
            left: 18.5%;
            bottom: 27px;
            font-size: 12px;
            color: #C5E1FF;
        }

        .top-l {
            width: 20%;
            margin-left: 10%;
            font-size: 14px;
            color: #c5e1ff;
            line-height: 16px;
        }

        .top-cot {

            height: 48px;
            font-size: 30px;
            font-weight: 800;
            color: #FFFFFF;
            line-height: 48px;
            letter-spacing: 9px;
            text-align: center;
            margin-bottom: 20px;
            flex: 1;
        }

        .top-r {
            width: 20%;
            margin-right: 8.5%;
            font-size: 14px;
            color: #c5e1ff;
            line-height: 16px;
            text-align: right;
            display: flex;
            cursor: pointer;
            justify-content: flex-end;

            .r-box {
                display: flex;
                align-items: center;

                &:active {
                    opacity: .5;
                }

                img {
                    width: 22px;
                    height: 22px;
                    vertical-align: middle;

                }

                span {

                    line-height: 24px;
                }
            }


        }
    }
    .content{
      position: relative;
      width: 100%;
      height: 80%;
      margin-top: 1%;
      display: flex;
      .leftChoose{
        height: 100%;
        width: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background:url("../../../assets/DataSet/ims-b.png");
        background-size: 100% 100%;
        background-position: center; /* 可选，用于控制图片在容器中的位置，center为居中 */
        background-repeat: no-repeat;
      }
      .leftOpen{
        position: absolute;
        z-index: 99;
        height: 100%;
        width: 20%;
        margin-left: 2%;
        padding: 20px 30px;
        font-size: 25px;
        color: #FFFFFF;
        cursor: pointer;
        overflow-y: auto;
        background:url("../../../assets/DataSet/ims-b.png");
        background-size: 100% 100%;
        background-position: center; /* 可选，用于控制图片在容器中的位置，center为居中 */
        background-repeat: no-repeat;
        .theHost{
          margin: 20px 0;
        }
        .chooseHost{

        }
        .chooseHost:hover {
          background-color: rgba(0, 0, 0, 0.2); /* 半透明的黑色背景 */
        }
        .chooseCamera{
          font-size: 20px;
          padding-left: 30px;
          padding-top: 10px;
          padding-bottom: 10px;
          border-radius: 8px;
        }

        .chooseCamera:hover {
          background-color: rgba(0, 0, 0, 0.2); /* 半透明的黑色背景 */
        }
      }
      .people{
        width: 16%;
        height: 28.7%;
        margin-left: 2%;
        margin-right: 2%;
        margin-top: 1%;
        margin-bottom: 1%;
        //display: flex;
        //align-items: center;
        //justify-content: center;
        //flex-wrap: wrap;
        text-align: center;
        background: url("../../../assets/DataSet/cameraSecord.png");
        background-size: 100% 100%;
        background-position: center; /* 可选，用于控制图片在容器中的位置，center为居中 */
        background-repeat: no-repeat;
      }
      //.video{
      //  height: 100%;
      //  //padding: 0.2% 0;
      //  width: 78%;
      //}
      //.rightShow{
      //  height: 100%;
      //  width: 14%;
      //  margin-left: 4%;
      //  padding: 10px 36px;
      //  //overflow-y: auto;
      //  //-ms-overflow-style: none;  /* IE 和 Edge */
      //  //scrollbar-width: none;     /* Firefox */
      //  cursor: pointer;
      //  background:url("../../../assets/DataSet/c-borr.png");
      //  background-size: 100% 100%;
      //  background-position: center; /* 可选，用于控制图片在容器中的位置，center为居中 */
      //  background-repeat: no-repeat;
      //}
    }


}
</style>
