var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "content-index" },
        [
          _c(
            "ScaleScreen",
            {
              staticClass: "scale-wrap",
              attrs: {
                width: 1920,
                height: 1080,
                selfAdaption: _vm.selfAdaption,
              },
            },
            [
              _c("div", { staticClass: "top" }, [
                _c("div", { staticClass: "top-l" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.date.year) +
                      "-" +
                      _vm._s(_vm.date.month) +
                      "-" +
                      _vm._s(_vm.date.date) +
                      " " +
                      _vm._s(_vm.date.hours) +
                      ":" +
                      _vm._s(_vm.date.minutes)
                  ),
                ]),
                _c("div", { staticClass: "week" }, [_vm._v(_vm._s(_vm.weeks))]),
                _c("div", { staticClass: "top-cot" }, [
                  _vm._v("新域伟科技无感考勤系统"),
                ]),
                _c("div", { staticClass: "top-r" }),
              ]),
              _c("div", { staticClass: "content" }, [
                _c(
                  "div",
                  { staticClass: "leftChoose", on: { click: _vm.openOrClose } },
                  [
                    _c("img", {
                      staticStyle: { width: "35px", height: "40px" },
                      attrs: { src: _vm.imgChange },
                    }),
                  ]
                ),
                _vm.leftOpen
                  ? _c(
                      "div",
                      { staticClass: "leftOpen" },
                      _vm._l(_vm.hostList, function (item, index) {
                        return _c("div", { staticClass: "theHost" }, [
                          _c(
                            "div",
                            {
                              staticClass: "chooseHost",
                              staticStyle: { "margin-bottom": "10px" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeHost(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "主机" +
                                  _vm._s(index + 1) +
                                  "：" +
                                  _vm._s(item.hostName)
                              ),
                            ]
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "98%",
                      height: "100%",
                      display: "flex",
                      "flex-wrap": "wrap",
                    },
                  },
                  _vm._l(_vm.peopleList, function (item, index) {
                    return _c("div", { staticClass: "people" }, [
                      item.image
                        ? _c("img", {
                            staticStyle: {
                              width: "65%",
                              height: "164px",
                              "margin-top": "10px",
                            },
                            attrs: { src: item.image },
                          })
                        : _vm._e(),
                      item.name
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#FFFFFF",
                                "font-size": "21px",
                                "margin-top": "7px",
                              },
                            },
                            [_vm._v("姓名：" + _vm._s(item.name))]
                          )
                        : _vm._e(),
                      item.time
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#FFFFFF",
                                "font-size": "16px",
                                "margin-top": "7px",
                              },
                            },
                            [_vm._v("时间：" + _vm._s(item.time))]
                          )
                        : _vm._e(),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }